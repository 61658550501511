import React from "react";
import './Resume.css'

function Resume() {
        return (
            <div className="super">
                 <div className="resume">
                </div>
            </div>
    )
}

export default Resume;

