const NavItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: '/projects',
        cName: 'nav-links'
    },
    {
        title: 'Resume',
        url: '/resume',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '/contact',
        cName: 'nav-links'
    },
    {
        title: 'GitHub',
        url: '/https://github.com/toastMaduro-hub',
        cName: 'nav-links-mobile'
    }
];

export default NavItems;